<!--用户数据列表-->
<template>
    <div>
        <div style="display:flex;justify-content: flex-end;margin-bottom:10px ;"><el-button icon="el-icon-download" style="color: #00A78E;border-color: #00A78E;" 
                @click="exportExcel">导出</el-button></div>
        <div style="display: flex;align-items: center;justify-content: space-between;">
            <!-- <el-date-picker v-model="times" type="daterange" range-separator="至" start-placeholder="开始日期"
                end-placeholder="结束日期" value-format="yyyy-MM-dd">
            </el-date-picker> -->
            <el-date-picker
                v-model="monthValue"
                type="month"
                placeholder="vip注册时间"
                value-format="yyyy-MM"
                >
            </el-date-picker>
            <el-button  @click="getUserList" style="color: #00A78E;border-color: #00A78E;" >搜索</el-button>

            <div style="display: flex;align-items: center;">
                <div>等级:</div>
                <el-select v-model="sortLevel" placeholder="请选择" style="width: 120px;">
                    <el-option v-for="item in sortlevelList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div style="display: flex;align-items: center;">
                <div>学完课程数量:</div>
                <el-select v-model="sortDone" placeholder="请选择" style="width: 120px;">
                    <el-option v-for="item in sortDoneList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div style="display: flex;align-items: center;">
                <div>通过数量:</div>
                <el-select v-model="sortPass" placeholder="请选择" style="width: 120px;">
                    <el-option v-for="item in sortPassList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div style="display: flex;align-items: center;">
                <div>当月学习时长:</div>
                <el-select v-model="sortStudyTime" placeholder="请选择" style="width: 120px;">
                    <el-option v-for="item in sortStudyTimeList" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                </el-select>
            </div>
            <div>总数:{{ tableData.length }}</div>
        </div>
        <el-table :data="tableData" style="width: 100%" :default-sort="{ prop: 'date', order: 'descending' }" id="out-table">
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="userName" label="用户" align="center"></el-table-column>
            <el-table-column prop="levelName.levelName" label="等级" align="center"></el-table-column>
            <el-table-column prop="storeName" label="门店名" align="center"></el-table-column>
            <el-table-column prop="phone" label="联系方式" align="center"></el-table-column>
            <el-table-column prop="studyTimeHourRange" label="当月学习时长(h)" align="center"></el-table-column>
            <el-table-column prop="studyTimeHourAll" label="总学习时长(h)" align="center"></el-table-column>
            <el-table-column prop="contentDoneCount" label="学完内容数量" align="center"></el-table-column>
            <el-table-column prop="courseDoneCount" label="学完课程数量" align="center"></el-table-column>
            <el-table-column prop="coursePassCount" label="通过数量" align="center"></el-table-column>
            <el-table-column prop="phone" label="会员状态">
                <template slot-scope="scope">
                    <div>
                        {{scope.row.vip==0?'非会员':scope.row.vip==1?'付费会员':scope.row.vip==2?'内部会员':scope.row.vip==3?'外部购买会员':scope.row.vip==0?'注销用户':'未定义'}}
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="asVipTime" label="vip购买时间" align="center"></el-table-column>
        </el-table>
    </div>
</template>

<script>
import {levelData} from '../utils/levelData'
import FileSaver from "file-saver";
import * as XLSX from 'xlsx'
import { assistantGetUserData } from '../apis/index'
export default {
    data() {
        return {
            monthValue:'',
            vips: '1,2',
            times: [],
            tableData: [],
            originData: [],
            sortlevelList: [
                {
                    value: '',
                    label: '默认'
                },
                {
                    value: 1,
                    label: '升序'
                },
                {
                    value: -1,
                    label: '降序'
                },
            ],
            sortLevel: '',
            sortDoneList: [
                {
                    value: '',
                    label: '默认'
                },
                {
                    value: 1,
                    label: '升序'
                },
                {
                    value: -1,
                    label: '降序'
                },
            ],
            sortDone: '',
            sortPassList: [
                {
                    value: '',
                    label: '默认'
                },
                {
                    value: 1,
                    label: '升序'
                },
                {
                    value: -1,
                    label: '降序'
                },
            ],
            sortPass: '',
            sortStudyTimeList: [
                {
                    value: '',
                    label: '默认'
                },
                {
                    value: 1,
                    label: '升序'
                },
                {
                    value: -1,
                    label: '降序'
                },
            ],
            sortStudyTime: '',
        }

    },
    watch: {
        sortLevel(newData, oldData) {
            let tableData = this.tableData
            if (newData == 1) {
                tableData = tableData.sort(this.sortAscending('levelId'))
            } else if (newData == -1) {
                tableData = tableData.sort(this.sortDescending('levelId'))
            } else {
                tableData = JSON.parse(JSON.stringify(this.originData))
            }
            this.tableData = tableData
        },
        sortDone(newData, oldData) {
            let tableData = this.tableData
            if (newData == 1) {
                tableData = tableData.sort(this.sortAscending('courseDoneCount'))
            } else if (newData == -1) {
                tableData = tableData.sort(this.sortDescending('courseDoneCount'))
            } else {
                tableData = JSON.parse(JSON.stringify(this.originData))
            }
            this.tableData = tableData
        },
        sortPass(newData, oldData) {
            let tableData = this.tableData
            if (newData == 1) {
                tableData = tableData.sort(this.sortAscending('coursePassCount'))
            } else if (newData == -1) {
                tableData = tableData.sort(this.sortDescending('coursePassCount'))
            } else {
                tableData = JSON.parse(JSON.stringify(this.originData))
            }
            this.tableData = tableData
        },
        sortStudyTime(newData, oldData) {
            let tableData = this.tableData
            if (newData == 1) {
                tableData = tableData.sort(this.sortAscending('studyTimeHourRange'))
            } else if (newData == -1) {
                tableData = tableData.sort(this.sortDescending('studyTimeHourRange'))
            } else {
                tableData = JSON.parse(JSON.stringify(this.originData))
            }
            this.tableData = tableData
        },
    },
    created() {

    },
    props: ["componentUseAgentId"],

    methods: {
        copyObj(newObj, oldObj) {
            for (let key in oldObj) {
                if (oldObj[key] instanceof Array) {
                    newObj[key] = []
                    this.copyObj(newObj[key], oldObj[key])
                } else if (oldObj[key] instanceof Object) {
                    newObj[key] = {}
                    this.copyObj(newObj[key], oldObj[key])
                } else {
                    newObj[key] = oldObj[key]
                }
            }
        },
        sortAscending: function (prop) {
            return function (obj1, obj2) {
                let value1 = obj1[prop]
                let value2 = obj2[prop]
                if (value1 > value2) {
                    return 1
                } else if (value1 < value2) {
                    return -1
                } else {
                    return 0
                }
            }
        },
        sortDescending: function (prop) {
            return function (obj1, obj2) {
                let value1 = obj1[prop]
                let value2 = obj2[prop]
                if (value1 > value2) {
                    return -1
                } else if (value1 < value2) {
                    return 1
                } else {
                    return 0
                }
            }
        },
        exportExcel() {//导出表格
            /* 从表生成工作簿对象 */
            let wb = XLSX.utils.table_to_book(document.querySelector('#out-table'))
                /* 获取二进制字符串作为输出 */
                var wbout = XLSX.write(wb, {
                    bookType: 'xlsx',
                    bookSST: true,
                    type: 'array'
                })
                try {
                    FileSaver.saveAs(
                        //Blob 对象表示一个不可变、原始数据的类文件对象。
                        //Blob 表示的不一定是JavaScript原生格式的数据。
                        //File 接口基于Blob，继承了 blob 的功能并将其扩展使其支持用户系统上的文件。
                        //返回一个新创建的 Blob 对象，其内容由参数中给定的数组串联组成。
                        new Blob([wbout], { type: 'application/octet-stream' }),
                        //设置导出文件名称
                        '学员列表.xlsx'
                    )
                } catch (e) {
                    if (typeof console !== 'undefined') console.log(e, wbout)
                }
                return wbout

        },
        async getUserList() {
            let d = new Date()
            // let year=d.getFullYear()
            // let month=(d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)
            let dateTimeStr=`${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)}-01`
            let dateTime = `${d.getFullYear()}-${(d.getMonth() + 1) < 10 ? '0' + (d.getMonth() + 1) : (d.getMonth() + 1)}-${d.getDate() < 10 ? '0' + d.getDate() : d.getDate()}`
            let times = this.times
            times = [dateTimeStr, dateTime]
            this.times = times
            this.sortLevel = this.sortDone = this.sortPass = this.sortStudyTime = ''
            const params = {
                agentUserId: this.componentUseAgentId,
                startDate: this.times[0],
                endDate: this.times[1],
                vips: this.vips,
                vipMonth:this.monthValue?this.monthValue.substring(5,7):'',
                vipYear:this.monthValue?this.monthValue.substring(0,4):''
            }
            const { data } = await assistantGetUserData(params)
            if (data.code == 200) {
                data.data.forEach(item => {
                    item.courseDoneCount = item.courseDoneCount ? item.courseDoneCount : 0
                    item.coursePassCount = item.coursePassCount ? item.coursePassCount : 0
                    item.levelName=item.levelId?levelData.find(item2=>item2.levelId==item.levelId):''
                })
                console.log(data.data)
                this.tableData = data.data
                this.originData = JSON.parse(JSON.stringify(data.data))

            } else {
                this.$message.error(data.msg)
            }
        },
    }
}
</script>

<style lang='less' scoped></style>
